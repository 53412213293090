<template>
  <Browse
    :columns="[
      'name',
      'note',
    ]"
    :formats="{
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="expense_types"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
